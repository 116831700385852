export default {
  text: 'black',
  primary: '#fdb917',
  secondary: '#9930a6',
  background: 'white',
  backgroundSecondary: 'lightgrey',
  brandOrange: '#ff991d',
  brandBrown: '#be602c',
  brandBlue: '#0bbbea',
  brandPurple: '#9930a6',
  brandGreen: '#27a750',
  light: '#FFF',
  dark: '#202020'
}
